/** @jsx jsx */
import { Title } from '@bottlebooks/gatsby-design-system';
import { Col, Container } from '@bottlebooks/gatsby-theme-base';
import useLink from '@bottlebooks/gatsby-theme-event/src//useLink';
import BrandAddressSection from '@bottlebooks/gatsby-theme-event/src/components/Brand/BrandAddressSection';
import BrandBanner from '@bottlebooks/gatsby-theme-event/src/components/Brand/BrandBanner';
import BrandShopsSection from '@bottlebooks/gatsby-theme-event/src/components/Brand/BrandShopsSection';
import MarketingActivitiesSection from '@bottlebooks/gatsby-theme-event/src/components/Brand/MarketingActivitiesSection';
import ContentRow from '@bottlebooks/gatsby-theme-event/src/components/ContentRow';
import ExhibitorActions from '@bottlebooks/gatsby-theme-event/src/components/Exhibitor/ExhibitorActions';
import ExhibitorHeaderRow from '@bottlebooks/gatsby-theme-event/src/components/ExhibitorPage/ExhibitorHeaderRow';
import ExhibitorSections from '@bottlebooks/gatsby-theme-event/src/components/ExhibitorPage/ExhibitorSections';
import Layout from '@bottlebooks/gatsby-theme-event/src/components/Layout';
import ProductCard from '@bottlebooks/gatsby-theme-event/src/components/ProductCard/ProductCard';
import ProductListListRow from '@bottlebooks/gatsby-theme-event/src/components/ProductListsList/ProductListListRow';
import ProductListsList from '@bottlebooks/gatsby-theme-event/src/components/ProductListsList/ProductListsList';
import ProductsList from '@bottlebooks/gatsby-theme-event/src/components/ProductsList/ProductsList';
import SEO from '@bottlebooks/gatsby-theme-event/src/components/seo.js';
import { Trans } from '@lingui/macro';
import { graphql as gatsbyGraphQL } from 'gatsby';
import { jsx } from 'theme-ui';

export default function ExhibitorPage({
  eventId,
  exhibitor,
  products,
  productLists,
  previous,
  location,
  next,
  locale,
}) {
  const link = useLink();
  const hasProducts = products && products.length !== 0;
  const hasProductLists = productLists && productLists.length !== 0;
  const hasProductsSection = hasProducts || hasProductLists;
  const page = {
    path: location.href,
    title: exhibitor.name,
    image: exhibitor.logo?.fixed?.src || '',
    description: exhibitor.shortDescription,
  };
  return (
    <Layout locale={locale} sx={{ backgroundColor: 'transparent' }}>
      <SEO page={page} />
      <BrandBanner {...exhibitor} />
      <ExhibitorHeaderRow
        exhibitor={exhibitor}
        previous={previous}
        next={next}
      />

      <Container>
        <ContentRow sx={{ paddingY: 3 }}>
          <Col>
            <ExhibitorActions exhibitor={exhibitor} />
          </Col>
        </ContentRow>
      </Container>

      <MarketingActivitiesSection brand={exhibitor} />

      <ExhibitorSections exhibitor={exhibitor} eventId={eventId} />

      <BrandAddressSection brand={exhibitor} />

      <Container>
        <ContentRow>
          <Col>
            <BrandShopsSection brand={exhibitor} />
          </Col>
        </ContentRow>
      </Container>

      {hasProductsSection ? (
        <Container fluid sx={{ maxWidth: 'container.fluid' }}>
          <Title sx={{ paddingY: 4 }}>
            <Trans>Products</Trans>
          </Title>
          {hasProductLists && (
            <ProductListsList sx={{ marginX: [-3, -2], marginY: -2 }}>
              {productLists.map((productList) => (
                <ProductListListRow
                  key={productList.id}
                  productList={productList}
                  to={link.productList(productList)}
                  sx={{ marginX: [0, 2], marginY: [0, 2] }}
                />
              ))}
            </ProductListsList>
          )}
          {hasProducts && (
            <ProductsList
              count={products.length}
              sx={{ marginX: [-3, -2], marginY: -2 }}
            >
              {products.map((product) => (
                <ProductCard
                  key={product.id}
                  product={product}
                  to={link.product(product)}
                  sx={{ marginBottom: [null, null, 3] }}
                />
              ))}
            </ProductsList>
          )}
        </Container>
      ) : null}
    </Layout>
  );
}

export const fragment = gatsbyGraphQL/* GraphQL */ `
  fragment ExhibitorPage on Exhibitor {
    ...BrandBanner
    ...ExhibitorHeaderRow
    ...MarketingActivitiesSection
    products {
      ...ExhibitorPage_Product
      ...useLink_Product
    }
  }

  fragment bb_ExhibitorPage_RegisteredBrands on Bottlebooks_RegisteredBrand {
    ...bb_ExhibitorSections_RegisteredBrand
  }

  fragment bb_ExhibitorPage on Bottlebooks_AbstractBrand {
    ...bb_ExhibitorActions
    ...bb_ExhibitorSections
    ...bb_BrandAddressSection
    ...bb_BrandShopsSection
    ... on Bottlebooks_Exhibitor {
      products {
        nodes {
          ...bb_ProductListRow
          ...bb_ProductCard
          ...useLink_bb_Product
        }
      }
    }
  }

  fragment bb_ExhibitorPage_SingleRegistration on Bottlebooks_SingleRegistration {
    ...bb_ExhibitorActions_SingleRegistration
  }

  fragment ExhibitorPage_Event on Event {
    name
    ...EventBanner
  }

  fragment ExhibitorPage_Product on Product {
    id
    slug
    ...ProductListRow
    ...ProductCard
    ...useLink_Product
  }

  fragment ExhibitorPage_ProductList on Event {
    id
    slug
    ...ProductListListRow
    ...useLink_ProductList
  }
`;
